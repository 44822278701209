<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap">
        <h1 class="tit-h1 d-flex">
          일별 상담완료현황
          <!-- 조회 버튼 -->
          <div class="ml-auto align-self-center">
            <v-btn class="btn-etc2" outlined small @click="getListData">조회</v-btn>
          </div>
        </h1>
        <!-- 조회 -->
        <div class="box-ct mt-2">
          <div class="table-form d-flex">
            <table>
              <caption class="hide">
                조회 항목
              </caption>
              <colgroup>
                <col width="148px" />
                <col width="280px" />
                <col width="148px" />
                <col width="280px" />
                <col width="148px" />
                <col width="280px" />
                <col width="148px" />
                <col width="150px" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      건설사
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="CTCP_CODE"
                        :items="ctcpTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        @change="changeBussType(CTCP_CODE)"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      사업지명
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="BUSS_CODE"
                        :items="bussTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        :disabled="bussTypeCd.length === 0 ? true : false"
                        @change="changeCmpType(BUSS_CODE)"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    캠페인
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="CMP_ID"
                        :items="cmpTypeCd"
                        item-text="CMP_NAME"
                        item-value="CMP_ID"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        :disabled="cmpTypeCd.length === 0 ? true : false"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      조회일자
                    </v-badge>
                  </th>
                  <td>
                    <v-menu 
                      v-model="menu1" 
                      :close-on-content-click="false" 
                      :nudge-left="40"
                      transition="scale-transition" 
                      offset-y 
                      min-width="auto" 
                      content-class="calendar-modal"
                      color="#FFF"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="form-inp full icon-calendar"
                          v-model="dateText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          append-icon="svg-calendar"
                        ></v-text-field>
                      </template>
                      <div class="datepicker-range">
                        <v-date-picker
                          v-model="date"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="selectDate"
                        ></v-date-picker>
                      </div>
                    </v-menu>
                  </td>
                </tr>
              </tbody> 
            </table>
          </div>
        </div>
        <!-- 목록 -->
        <div class="box-ct mt-3">
          <h1 class="tit-h1 d-flex">
            상담현황
          </h1>
          <div class="mt-2">
            <v-data-table
              dense
              v-model="selGroupCd"
              :headers="gridDataHeaders"
              :items="gridDataText"
              :items-per-page="itemsPerPage"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              @page-count="pageCount = $event"
              fixed-header
              class="grid-default"
              height="570px"
              no-data-text="검색된 결과가 없습니다."
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "MENU_STM0300", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        // 공통코드
        allCodeList  : [],
        USER_AUTH_CHK: '', // 사용자 권한 체크

        // 조회
        CTCP_CODE: '',
        CTCP_NAME: '',
        BUSS_CODE: '',
        CMP_ID   : '',
        menu1    : false,
        date     : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),

        // select box
        ctcpTypeCd: [], // 건설사명
        bussTypeCd: [], // 사업지명
        cmpTypeCd : [], // 캠페인명

        // 현황 목록
        gridDataHeaders: [
          { text: "No", value: "index", align: " d-none", width: "0px", sortable: false  },
          { text: "상담사", value: "CNSLR_ID", align: "center", width: "100px", sortable: false },
          { text: "08시", value: "CNSL_RSVT_DT08", align: "center", width: "100px", sortable: false },
          { text: "09시", value: "CNSL_RSVT_DT09", align: "center", width: "100px", sortable: false },
          { text: "10시", value: "CNSL_RSVT_DT10", align: "center", width: "100px", sortable: false },
          { text: "11시", value: "CNSL_RSVT_DT11", align: "center", width: "100px", sortable: false },
          { text: "12시", value: "CNSL_RSVT_DT12", align: "center", width: "100px", sortable: false },
          { text: "13시", value: "CNSL_RSVT_DT13", align: "center", width: "100px", sortable: false },
          { text: "14시", value: "CNSL_RSVT_DT14", align: "center", width: "100px", sortable: false },
          { text: "15시", value: "CNSL_RSVT_DT15", align: "center", width: "100px", sortable: false },
          { text: "16시", value: "CNSL_RSVT_DT16", align: "center", width: "100px", sortable: false },
          { text: "17시", value: "CNSL_RSVT_DT17", align: "center", width: "100px", sortable: false },
          { text: "18시", value: "CNSL_RSVT_DT18", align: "center", width: "100px", sortable: false },
          { text: "19시", value: "CNSL_RSVT_DT19", align: "center", width: "100px", sortable: false },
          { text: "합계", value: "TOTAL", align: "center", width: "100px", sortable: false },
          { text: "사업지코드", value: "BUSS_CODE", align: " d-none", width: "0px", sortable: false },
          { text: "사업지명", value: "BUSS_NAME", align: " d-none", width: "0px", sortable: false },
          { text: "캠페인ID", value: "CMP_ID", align: " d-none", width: "0px", sortable: false },
          { text: "캠페인", value: "CMP_NAME", align: " d-none", width: "0px", sortable: false },
        ],
        gridDataText: [], // 상담사 목록 데이터
        selGroupCd  : [],

        // 기타
        GE_USER_ID: '', // 사용자아이디

        // 메시지
        stm0300Msg: {
          ctcrCdEmptyChk    : '건설사를 선택하세요.',
          bussNameEmptyChk  : '사업지명을 선택하세요.',
          cnslRsvtDtEmptyChk: '조회일자를 선택하세요.',
        },

        // 페이징
        page        : 1,
        pageCount   : 0,
        itemsPerPage: 30,
        totalVisible: 10,
      };
    },
    methods: {
      // 일별고객현황 조회
      async getListData() {
        // 유효성 체크
        if(!this.searchValidate()) {
          return;
        }

        let requestData = {
          headers: {},
          sendData:{},
        }

        //header 세팅
        requestData.headers["URL"] = "/api/report/report-manage/dayCnslrList";
        requestData.headers["SERVICE"] = "report.report-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TWB_SQL_ID"] = "selectDayCnslrList";
        requestData.headers["ASYNC"] = false;
        requestData.headers["GRID_ID"] = "gridDataHeaders";

        //sendData 초기화
        requestData.sendData["CTCP_CODE"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["CMP_ID"] = this.CMP_ID;
        requestData.sendData["CNSL_RSVT_DT"] = this.date;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 일별고객현황 조회 결과
      getGridDataCallBack(res){
        if(res.HEADER.ERROR_FLAG) {
          this.gridDataText = [];
          return;
        }
        let data = res.DATA;
        this.gridDataText = data;

        let idx = 1;

        for(var i in this.gridDataText) {
          this.gridDataText[i]["index"] = idx++;
          this.gridDataText[i]["CNSL_RSVT_DT09"] = Number(this.gridDataText[i].CNSL_RSVT_DT09);
          this.gridDataText[i]["CNSL_RSVT_DT10"] = Number(this.gridDataText[i].CNSL_RSVT_DT10);
          this.gridDataText[i]["CNSL_RSVT_DT11"] = Number(this.gridDataText[i].CNSL_RSVT_DT11);
          this.gridDataText[i]["CNSL_RSVT_DT12"] = Number(this.gridDataText[i].CNSL_RSVT_DT12);
          this.gridDataText[i]["CNSL_RSVT_DT13"] = Number(this.gridDataText[i].CNSL_RSVT_DT13);
          this.gridDataText[i]["CNSL_RSVT_DT14"] = Number(this.gridDataText[i].CNSL_RSVT_DT14);
          this.gridDataText[i]["CNSL_RSVT_DT15"] = Number(this.gridDataText[i].CNSL_RSVT_DT15);
          this.gridDataText[i]["CNSL_RSVT_DT16"] = Number(this.gridDataText[i].CNSL_RSVT_DT16);
          this.gridDataText[i]["CNSL_RSVT_DT17"] = Number(this.gridDataText[i].CNSL_RSVT_DT17);
          this.gridDataText[i]["CNSL_RSVT_DT18"] = Number(this.gridDataText[i].CNSL_RSVT_DT18);
          this.gridDataText[i]["CNSL_RSVT_DT19"] = Number(this.gridDataText[i].CNSL_RSVT_DT19);
          this.gridDataText[i]["TOTAL"] = Number(this.gridDataText[i].TOTAL);
        }
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "list") {
          this.getGridDataCallBack(response);
        }
      },

      // 조회일자 선택
      selectDate(e) {
        this.date = e;
      },

      // 소계 및 합계 계산
      sumField(key) {
        return this.gridDataText.reduce((a, b) => a + (b[key] || 0), 0);
      },

      //사업지 코드 가져오기
      async changeBussType(ctcpTypeCd) {
        if(ctcpTypeCd !== '') {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.bussTypeCd.push({ CD: '', CD_NM: '' });
          this.CMP_ID =  "";
          this.cmpTypeCd = [];

          if(this.USER_AUTH_CHK === "SYSTEMMANAGER") {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
          }
        }else {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 캠페인 코드 가져오기
      async changeCmpType(bussTypeCd) {
        if(bussTypeCd !== '') {
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
          this.cmpTypeCd.push({ CMP_ID: "", CMP_NAME: "" });
          this.cmpTypeCd = await this.mixin_cmpcode_get(bussTypeCd);
        }else {
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 유효성 체크
      searchValidate() {
        if(this.mixin_isEmpty(this.CTCP_CODE)) {
          this.common_alert(this.stm0300Msg.ctcrCdEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.BUSS_CODE)) {
          this.common_alert(this.stm0300Msg.bussNameEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.dateText)) {
          this.common_alert(this.stm0300Msg.cnslRsvtDtEmptyChk, 'chk');
          return;
        }
        return true;
      },
    },

    async mounted() {
      let userGroupCd = this.user_role.userGroupCd;
      this.GE_USER_ID = this.user_id;

      let pArr = ['HLW_USE_YN', 'HLW_PLT_AUTH', 'HLW_CTCP_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      if(userGroupCd === "SYSTEMMANAGER") {
        this.USER_AUTH_CHK = userGroupCd;  
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
      }else{
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);

        this.ctcpTypeCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
      }
    },

    computed: {
      dateText() {
        var newDate = this.date;
        let returnVal = "";

        if(newDate !== '') {
          returnVal = newDate;
        }
        return returnVal;
      },
      initHeaders() {
        return {
        };
      },
    },
  };
</script>